const dspRouter = {
  route: null,
  name: null,
  title: '预算管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/ssp/', // 文件路径
  order: 3,
  inNav: true,
  children: [
    {
      title: 'DSP管理',
      type: 'view',
      name: 'dspList',
      route: '/dsp/list',
      filePath: 'views/dsp/dsp-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },
    {
      title: 'DSP新增/编辑',
      type: 'view',
      name: 'dspAdd',
      route: '/dsp/add',
      filePath: 'views/dsp/dsp-add.vue',
      inNav: false,
    },
    {
      title: 'DSP计划',
      type: 'view',
      name: 'dspGroupList',
      route: '/dsp/dsp-group-list',
      filePath: 'views/dsp/dsp-group-list.vue',
      inNav: false,
      icon: 'iconfont icon-tushuguanli'
    },
    {
      title: 'DSP计划管理',
      type: 'view',
      name: 'dspGroupList',
      route: '/dsp/dsp-group-add',
      filePath: 'views/dsp/dsp-group-add.vue',
      inNav: false,
    },
    {
      title: '组流量定向',
      type: 'view',
      name: 'dspGroupIxList',
      route: '/dsp/dsp-group-ix-add',
      filePath: 'views/dsp/dsp-group-ix-add.vue',
      inNav: false,
    },
    {
      title: 'DSP点位映射',
      type: 'view',
      name: 'dspMappingList',
      route: '/dsp/dsp-mapping-list',
      filePath: 'views/dsp/dsp-mapping-list.vue',
      inNav: true,
      icon: 'iconfont icon-add',
    },
    {
      title: 'DSP映射策略',
      type: 'view',
      name: 'dspMappingAdd',
      route: '/dsp/dsp-mapping-add',
      filePath: 'views/dsp/dsp-mapping-add.vue',
      inNav: false,
      icon: 'iconfont icon-add',
    }
  ],
}

export default dspRouter
