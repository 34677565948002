/* eslint-disable class-methods-use-this */
import _axios, {get, put, post, _delete} from '@/lin/plugin/axios'
import store from '@/store'

// 我们通过 class 这样的语法糖使模型这个概念更加具象化，其优点：耦合性低、可维护性。
class User {
  /**
   * 登陆
   * @param data {phone,password}
   */
  login(phone, password) {
    return _axios({
      method: 'post',
      url: 'user/login',
      data: {
        phone: phone,
        password: password,
      }
    })
  }

  /**
   * 登陆成功后，token保存到本地local
   * @param token
   */
  saveToken(token) {
    console.log('saveToken:',token)
    localStorage.setItem('access_token', `Bearer ${token}`)
  }

  /**
   * 每个后端请求&route跳转，都需要判断是否登陆以及是否有token
   * @param tokenKey
   * @returns {string | null}
   */
  getToken(tokenKey) {
    console.log('getToken:',tokenKey)
    return localStorage.getItem(tokenKey)
  }

  /**
   * 用户退出需要清空token
   */
  removeToken() {
    localStorage.removeItem('access_token')
  }

  async updateUser(data) {
    return _axios({
      method: 'post',
      url: `user/editProfilePath`,
      data: data
    })
  }

  async updatePwd(data) {
    return _axios({
      method: 'post',
      url: `user/editPwd`,
      data: data
    })
  }

  getCurrentUser(data) {
    return _axios({
      method: 'post',
      url: `user/getListUserInfo`,
      data: data
    })
  }
  uploadAvatar(file) {
    return _axios({
      method: 'post',
      url: `app/uploadFileAllPath`,
      data: {file},
    })
  }

}

export default new User()
