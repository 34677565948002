const bookRouter = {
  route: null,
  name: null,
  title: '数据报表',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/report/', // 文件路径
  order: 4,
  inNav: true,
  children: [
    {
      title: '流量维度',
      type: 'view',
      name: 'TrafficReport',
      route: '/report/traffic',
      filePath: 'views/report/report-traffic.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },
    {
      title: '预算维度',
      type: 'view',
      name: 'TrafficReport',
      route: '/report/budget',
      filePath: 'views/report/report-dsp.vue',
      inNav: true,
      icon: 'iconfont icon-add',
    },
  ],
}

export default bookRouter
